import React, { ReactNode } from 'react';

interface IProps {
	children?: ReactNode;
}
interface IState {
	error: Error | null;
}
class ErrorBoundary extends React.Component<IProps, IState> {
	static getDerivedStateFromError(error: Error | null) {
		return { error };
	}

	state: IState = {
		error: null
	};

	componentDidCatch(error: Error | null) {
		this.logError(error);
	}

	render() {
		if (this.state.error) {
			return 'Application has errors. Please check logs to fix this';
		}
		return this.props.children;
	}

	logError(args: Error | null) {
		console.log(args);
	}
}

export default ErrorBoundary;
